import "./FAQ.scss";
import { CaretRightOutlined } from "@ant-design/icons";
import { Collapse, theme } from "antd";
import { FAQs } from "./FAQs";

const { Panel } = Collapse;

const panelStyle = {
  marginBottom: 24,
  background: "#001322",
  border: "none",
};

export const FAQ = () => {
  return (
    <div className="faq-main-container">
      <div className="faq-container">
        <div className="hero">
          <div className="title">Degen FAQs</div>
          <div className="subtitle">
            Q - "Am I getting rugged?"
            <br />A - no, bozo. Take a breath, shut up and read.
          </div>
        </div>
        <Collapse
          style={{ marginTop: "50px" }}
          bordered={false}
          expandIcon={({ isActive }) => (
            <CaretRightOutlined rotate={isActive ? 90 : 0} />
          )}
        >
          {FAQs.map(({ title, description }, index) => (
            <Panel
              header={<div style={{ fontSize: "1.2rem" }}>{title}</div>}
              key={index}
              style={panelStyle}
            >
              {description}
            </Panel>
          ))}
        </Collapse>
      </div>
    </div>
  );
};
