import { BrowserRouter } from "react-router-dom";
import { WalletService } from "./Services/WalletService/WalletService";
import "./App.scss";
import { FirebaseService } from "./Services/FirebaseService/FirebaseService";
import { MainPage } from "./pages/MainPage";
import { PoolFilterService } from "./Services/PoolFilterService/PoolFilterService";

function App() {
  return (
    <BrowserRouter>
      <WalletService>
        <FirebaseService>
          <PoolFilterService>
            <MainPage />
          </PoolFilterService>
        </FirebaseService>
      </WalletService>
    </BrowserRouter>
  );
}

export default App;
