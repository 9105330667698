import solscanLogo from "../../assets/images/solscan-logo.png";
import type { ColumnsType } from "antd/lib/table";
import { DataType } from "./types";
import Tooltip from "antd/es/tooltip";

export const columns: ColumnsType<DataType> = [
  {
    title: "Wallet",
    dataIndex: "walletAddress",
    render: (value, row) => (
      <div style={{ display: "flex", alignItems: "center" }}>
        {value.slice(0, 4)}...${value.slice(-4)}
        <Tooltip title="View in Solscan">
          <a
            href={`https://solscan.io/tx/${row.txid}`}
            target="_blank"
            rel="noreferrer"
            style={{ display: "inline-block", marginLeft: 5 }}
          >
            <img
              src={solscanLogo}
              alt="Solscan"
              width={20}
              style={{ display: "block" }}
            />
          </a>
        </Tooltip>
      </div>
    ),
  },
  {
    title: "Selections",
    dataIndex: "selections",
    render: (value) => value.join(", "),
  },
  {
    title: "Random",
    dataIndex: "randomNum",
  },
  {
    title: "Amount",
    dataIndex: "solAmount",
    render: (value, row) => (
      <div
        className="solana-number"
        style={{ color: row.status === "miss" ? "red" : "green" }}
      >
        <img
          src="https://cryptologos.cc/logos/solana-sol-logo.svg?v=022"
          alt="Solana"
        />
        {(row.status === "miss" ? "-" : "+") + (+value).toLocaleString()}{" "}
      </div>
    ),
  },
  {
    title: "Date",
    dataIndex: "created",
    render: (value) => {
      const date = value.toDate();
      return `${date.toDateString()} ${date.toLocaleTimeString("en-US")}`;
    },
  },
];
