import { Button, Divider } from "antd";
import { useEffect, useState } from "react";
import { useFirebase } from "../../Services/FirebaseService/FirebaseService";
import dejohn from "../../assets/images/dejohn.gif";
import "./Hero.scss";
import { NavLink } from "react-router-dom";

const DEFAULT_STATE = { trys: 0, livePools: 0, totalPoolPrize: 0 };

export const Hero = () => {
  const [{ trys, livePools, totalPoolPrize }, setStats] = useState({
    trys: 0,
    livePools: 0,
    totalPoolPrize: 0,
  });
  const { firestore } = useFirebase();

  useEffect(() => {
    return firestore.doc(`/APoolAnalytics/APoolAnalytics`).onSnapshot((doc) => {
      setStats({ ...((doc.data() as any) || DEFAULT_STATE) });
    });
  }, []);
  return (
    <div className="hero-main-container">
      <img className="dejohn" src={dejohn} alt="Dejohn" />
      <div className="title">STAY DEGEN</div>
      <div className="slogan">Your best shot at a 100X</div>

      <div className="faq-leader">
        Q - "Am I getting rugged?"
        <br />
        A - Probably, but not here fam.
        <br />
        <NavLink to={"/degen-faq"}>
          <Button style={{ margin: "auto", marginTop: "10px" }} type="primary">
            CLICK HERE & READ FAQ MFer
          </Button>
        </NavLink>
      </div>

      <div className="stats-container">
        <div className="stat">
          <div className="label">Live Pools</div>
          <div className="value">{livePools}</div>
        </div>
        <Divider style={{ height: "30px" }} type="vertical" dashed />
        <div className="stat">
          <div className="label">Total Prizes</div>
          <div className="value">
            {totalPoolPrize.toLocaleString()}{" "}
            <img
              className="sol-icon"
              src="https://cryptologos.cc/logos/solana-sol-logo.svg?v=022"
              alt="avatar"
            />
          </div>
        </div>
        <Divider style={{ height: "30px" }} type="vertical" dashed />
        <div className="stat">
          <div className="label">Tries</div>
          <div className="value"> {trys.toLocaleString()}</div>
        </div>
      </div>
    </div>
  );
};
