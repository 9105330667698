import { Button, Checkbox, Divider, Form, InputNumber } from "antd";
import { usePoolFilter } from "../../Services/PoolFilterService/PoolFilterService";
import "./Filter.scss";
const Filter = ({ setPoolType }: any) => {
  const {
    showAdvancedFilters,
    setShowAdvancedFilters,
    minRange,
    setMinRange,
    maxRange,
    setMaxRange,
    minOpportunity,
    setMinOpportunity,
    maxOpportunity,
    setMaxOpportunity,
    minEntry,
    setMinEntry,
    maxEntry,
    setMaxEntry,
    getPoolsIds,
  } = usePoolFilter();

  return (
    <div className="main-filter-container">
      <div className="form-container">
        <Form.Item label="Show advanced filters">
          <Checkbox
            checked={showAdvancedFilters}
            onChange={(val) => {
              setShowAdvancedFilters!(val.target.checked);
            }}
          />
        </Form.Item>
        <Form layout="inline" className="styled-form">
          <Form.Item label="Min Price">
            <InputNumber
              min={0.005}
              style={{ width: "100px" }}
              defaultValue={0.001}
              max={1000}
              step={0.001}
              addonBefore="◎"
              value={minEntry}
              onChange={(val) => {
                if (setMinEntry) setMinEntry(val || 0.001);
              }}
            />
          </Form.Item>

          <Form.Item label="Max Price">
            <InputNumber
              min={0.005}
              style={{ width: "100px" }}
              defaultValue={1000}
              max={1000}
              addonBefore="◎"
              value={maxEntry}
              onChange={(val) => {
                if (setMaxEntry) setMaxEntry(val || 0.001);
              }}
            />
          </Form.Item>
        </Form>

        {showAdvancedFilters && (
          <>
            <Divider style={{ marginTop: 0 }} />
            <Form layout="inline" className="styled-form">
              <Form.Item label="Min range">
                <InputNumber
                  min={2}
                  max={maxRange}
                  defaultValue={2}
                  value={minRange}
                  onChange={(val) => {
                    setMinRange!(val || 2);
                  }}
                />
              </Form.Item>
              <Form.Item label="Max range">
                <InputNumber
                  min={minRange}
                  max={1000}
                  defaultValue={1000}
                  value={maxRange}
                  onChange={(val) => {
                    setMaxRange!(val || 1000);
                  }}
                />
              </Form.Item>
              <Form.Item label="Min opportunity">
                <InputNumber
                  min={0.01}
                  max={maxOpportunity}
                  defaultValue={0.1}
                  value={minOpportunity}
                  onChange={(val) => {
                    setMinOpportunity!(val || 2);
                  }}
                />
              </Form.Item>
              <Form.Item label="Max opportunity">
                <InputNumber
                  min={minRange}
                  max={10000}
                  defaultValue={100}
                  value={maxOpportunity}
                  onChange={(val) => {
                    setMaxOpportunity!(val || 1000);
                  }}
                />
              </Form.Item>
            </Form>
          </>
        )}

        <Button
          type="primary"
          onClick={() => {
            if (getPoolsIds) {
              getPoolsIds();
              setPoolType("all");
            }
          }}
        >
          Find Pools
        </Button>
      </div>
      <Divider />
    </div>
  );
};

export default Filter;
