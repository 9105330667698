import { Form, Segmented, Select } from "antd";
import { BarsOutlined } from "@ant-design/icons";

import "./Order.scss";
import { usePoolFilter } from "../../Services/PoolFilterService/PoolFilterService";
import { SortOption } from "../../types";
import { SORT_OPTIONS } from "../../utils";

export const Order = () => {
  const { layout, setLayout, sortBy, setSortBy } = usePoolFilter();
  return (
    <div className="order-main-container">
      <Segmented
        value={layout}
        onChange={(e) => setLayout!(e.toString())}
        options={[
          {
            value: "List",
            icon: <BarsOutlined />,
          },
        ]}
      />
      <Form layout="inline">
        <Form.Item label="Order by">
          <Select
            defaultValue={sortBy?.value}
            style={{ width: 200 }}
            onChange={(e, option) => {
              setSortBy!(option as SortOption);
            }}
            options={SORT_OPTIONS}
          />
        </Form.Item>
      </Form>
    </div>
  );
};
