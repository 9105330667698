import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/analytics";
import "firebase/functions";
import "firebase/storage";
import { createContext, useContext } from "react";
import * as axios from "axios";

let app: firebase.app.App | undefined = firebase.initializeApp({
  apiKey: "AIzaSyA8VCG8w3pWsSBHpzg8gHTUDpju06zywn0",
  authDomain: "stay-degens.firebaseapp.com",
  projectId: "stay-degens",
  storageBucket: "stay-degens.appspot.com",
  messagingSenderId: "364245820209",
  appId: "1:364245820209:web:67a4d5e4813ea3ddc20610",
  measurementId: "G-VEWQX2L49C",
});
app.analytics();
export const firestore = app.firestore();
firestore.settings({ experimentalForceLongPolling: true });
export const auth = app.auth();
export const storage = app.storage();
let host = window.location.host;
host = host.includes("localhost") ? "staydegen.com" : host;
// export const functions = app.functions(
//   `https://${host}/api/v1`
// );
export const functions = {
  httpsCallable: (name: string) => (data: any) =>
    customFirebaseRequest(name, data),
};

type FirebaseFeatures = {
  firestore: firebase.firestore.Firestore;
  auth: firebase.auth.Auth;
  functions: any;
  storage: firebase.storage.Storage;
  customFirebaseRequest: (functionName: string, data: any) => Promise<any>;
  signupWithTwitter?: () => Promise<any>;
  setLoadingUserState?: any;
  setLoadingUserDocState?: any;
};

const customFirebaseRequest = (functionName: string, data: any = {}) =>
  new Promise<any>((resolve) => {
    axios.default
      .post<any>(
        `https://${host}/api/v1${functionName}`,
        {
          // axios.default
          //   .post<any>(
          //     `https://us-central1-nft-anybodies.cloudfunctions.net/${functionName}`,
          //     {
          data,
        },
        { timeout: 2 * 60 * 1000 }
      )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error: any) => {
        // handle error
        console.log(error);
        return resolve(error);
      });
  });

const FirebaseContext = createContext<FirebaseFeatures>({
  firestore,
  auth,
  functions,
  storage,
  customFirebaseRequest,
});

export function useFirebase() {
  return useContext(FirebaseContext);
}

export const FirebaseService = ({ children }: any) => {
  const value: FirebaseFeatures = {
    firestore,
    auth,
    functions,
    storage,
    customFirebaseRequest,
  };
  return (
    <FirebaseContext.Provider value={value}>
      {children}
    </FirebaseContext.Provider>
  );
};
