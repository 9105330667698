import { OrderByFields } from "./types";

export const PAGINATION_LIMIT = 20;

export const SORT_OPTIONS = [
  {
    label: "Best Hit Chance",
    value: 7,
    field: OrderByFields.Range,
    direction: "asc",
  },
  {
    label: "Biggest Opportunity",
    value: 1,
    field: OrderByFields.MaxOpportunity,
    direction: "desc",
  },
  // {
  //   label: "Smallest Opportunity",
  //   value: 0,
  //   field: OrderByFields.MaxOpportunity,
  //   direction: "asc",
  // },

 
  // {
  //   label: "Worst hit chance",
  //   value: 6,
  //   field: OrderByFields.Range,
  //   direction: "desc",
  // },

  {
    label: "Largest Pool Size",
    value: 3,
    field: OrderByFields.PoolSize,
    direction: "desc",
  },
  // {
  //   label: "Smallest pool size",
  //   value: 2,
  //   field: OrderByFields.PoolSize,
  //   direction: "asc",
  // },

  {
    label: "Low Entry Price",
    value: 5,
    field: OrderByFields.MinEntry,
    direction: "asc",
  },
  {
    label: "High Entry Price",
    value: 4,
    field: OrderByFields.MinEntry,
    direction: "desc",
  },

  
];

export const getMsgFromExeption = (e: any) => {
  if (e?.errorFields) return null;
  return {
    message: e?.message
      ? e.message
      : e?.cause
      ? "Error"
      : "Something went wrong. Try again..",
    ...(e?.cause && {
      description: e?.cause ? e.cause : "Something went wrong. Try again..",
    }),
    placement: "top",
  };
};

export const convertFloatToView = (num: number) => {
  return num % 1 === 0 ? num : parseFloat(num.toFixed(2));
};
