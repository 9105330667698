import "./EntryFormOption.scss";

export const EntryFormOption = ({
  index,
  selections,
  setSelections,
  isMaxed,
}: any) => {
  const change = () => {
    if (isSelected()) {
      var indexToRemove = selections.indexOf(index);
      if (indexToRemove !== -1) {
        selections.splice(indexToRemove, 1);
      }
      setSelections([...selections]);
    } else {
      if (isMaxed() === false) {
        setSelections([...selections, index]);
      } else {
        selections.shift();
        setSelections([...selections, index]);
      }
    }
  };
  const isSelected = () => selections.includes(index);

  return (
    <span className="button" onClick={change}>
      {!isSelected() && (
        <div className="qube">
          <div className="front">{index}</div>
          <div className="back">{index}</div>
        </div>
      )}
      {isSelected() && (
        <div className="qube-selected">
          <div className="back">{index}</div>
          <div className="front">{index}</div>
        </div>
      )}
    </span>
  );
};
