import { App, Button, Form, Input, Table, Tooltip } from "antd";
import { HowToPlayPreview } from "../../components/EntryForm/EntryForm";
import { CopyOutlined } from "@ant-design/icons";
import TextArea from "antd/lib/input/TextArea";

const HASH =
  "ad3af31b413443708a5342f8b35ddca6f2ccb63e826eedd50168f6c91368d23e4607f9d54d9a1cda8dc7a598437dda36eb5b35a2d0ea4b3a40b5b03d2fbaaa99";
const HASH_MESSAGE =
  "Hash Reference: f6590fde-785b-49bd-8cf6-0fa06daf52f6\n\nWinning number is: #69\n\nGenerated at: 2023-01-01";

const dataSource = [
  {
    key: "1",
    to: "Pool Creator",
    percent: "50%",
  },
  {
    key: "2",
    to: "Prize Pool",
    percent: "45%",
  },
  {
    key: "3",
    to: "Platform",
    percent: "5%",
  },
];

const columns = [
  {
    title: "To",
    dataIndex: "to",
    key: "to",
  },
  {
    title: "Percent",
    dataIndex: "percent",
    key: "percent",
  },
];
export const FAQClipboard = ({ text, title, action, rows }: any) => {
  const { notification } = App.useApp();

  return (
    <Form.Item tooltip={title}>
      <Input.Group compact>
        <TextArea
          rows={rows}
          style={{ width: "calc(100% - 31px)" }}
          value={text}
          disabled
        />
        <Tooltip title={action}>
          <Button
            icon={<CopyOutlined />}
            onClick={() => {
              navigator.clipboard
                .writeText(text)
                .then(() => {
                  notification.success({
                    message: "Copied to clipboard",
                  });
                })
                .catch((err) => {
                  notification.error({
                    message: "Error",
                    description: err.message,
                  });
                });
            }}
          />
        </Tooltip>
      </Input.Group>
    </Form.Item>
  );
};

export const FAQs = [
  {
    title: `What is "STAY DEGEN"?`,
    description: <div>The worlds first P2P Degeneracy Platform</div>,
  },
  {
    title: "What is a pool?",
    description: (
      <div>
        A Pool is a cash prize that was setup by one of your fellow Degens. not
        us.
        <br />
        <br />
        When they created the pool, they configured the following setting:
        <br />
        <ol>
          <li>
            Initial Pool Size - How much SOL will the pool hold when it is
            launched.
          </li>
          <li>
            The Range - How many numbers a users needs to choose from.
            <br />
            Between 2 - 1,000.
          </li>
          <li>
            Entry Prices - How many numbers can be picked in one round, and how
            much entry will cost depending on the number of selections.
          </li>
        </ol>
      </div>
    ),
  },
  {
    title: "How to play?",
    description: (
      <div>
        It's really simple:
        <br />
        <br />
        <ol>
          <li>
            You first need to find a pool that suites your degen taste.
            <br />
            You can search, filter and sort the Pools from the home page.
          </li>
          <li>Click on a Pool to navigate to the Pool-Entry page.</li>
          <li>
            Pick the number/numbers you think will be rolled.
            <br />
            Your selected numbers will change from white to green - indicating
            that you have selected them.
            <HowToPlayPreview />
          </li>
          <li>
            After choosing your lucky number/numbers - Click the entry button
            and pay the entry fee:
            <br />
            <Button type="primary" size="large">
              Entry button will look like this
            </Button>
          </li>
          <li>
            Sign the entry fee transaction and wait to find out if your rich or
            brokie.
          </li>
        </ol>
        Yip, that simple. We already know what your next question will be tho...
      </div>
    ),
  },
  {
    title:
      "How do I know I'm not getting rugged by the platfrom in the background?",
    description: (
      <div>
        You don't..
        <br />
        <br />
        Jokes.
        <br />
        Focus mfer, this is where you learn some new shit.
        <br />
        In short: The winning number is already selected and GIVEN TO YOU BEFORE
        you even begin picking your lucky numbers.
        <br />
        <br />
        -Q: "What? How? I will win every time if you show me the winning
        numbers"
        <br />
        -A: What? No bozo!.
        <br />
        <Button href="https://qr.ae/pvu3lU" target="_blank" type="link">
          Don't be stupid, CLICK HERE and learn something
        </Button>
        <br />
        <br />
        For the degens among us that didn't understand the explanation above,
        here is how we prove fairness:
        <br />
        <ol>
          <li>Every pool-entry session is uniuqe.</li>
          <li>
            After the session is loaded and the board is set - you will be given
            a one-time unique hash that looks something like this:
            <br />
            <FAQClipboard
              text={HASH}
              rows={2}
              action={"Copy hash message"}
              title={"This is a SHA512 Hash of the predefined winning number"}
            />
          </li>
          <li>
            BEFORE you submit your picks - copy the hash and store it somewhere
            for later.
          </li>
          <li>
            After you submit your picks, we will return the original message
            that was Hashed - AKA the Entry-Session-Hash, from point (2).
          </li>
          <li>
            The message WILL INCLUDE the winning number that was encrypted in
            the hash.
            <br />
            The message will look something like this:
            <br />
            <FAQClipboard
              text={HASH_MESSAGE}
              rows={5}
              action={"Copy original message"}
              title={"This is a the original message"}
            />
          </li>
          <li>
            Now that you lost the round, you wanna know for sure you didn't get
            rugged.
          </li>
          <li>
            Go to a <Button>SHA512 Encryption site</Button> - and copy the
            original message, from point (5)
            <FAQClipboard
              text={HASH_MESSAGE}
              rows={5}
              action={"Copy original message"}
              title={"This is a the original message"}
            />
            and paste it in to the input field. Click the "Encrypt" button and
            the site will spit out the Hash of that message.
            <br />
            <br />
          </li>
          <li>
            Compare the output Hash to the Hash from point (2), that was given
            to you before you made your picks and submited them.
            <FAQClipboard
              text={HASH}
              rows={2}
              action={"Copy hash message"}
              title={"This is a SHA512 Hash of the predefined winning number"}
            />
          </li>
          <li>
            If the two Hashes are the same - you can be sure that the number was
            pre-selected when the page was loaded, before you made your picks,
            and was not changed.
          </li>
        </ol>
        This is how we make sure you know that you were not rugged. You just
        have shit luck, brokie.
        <br />
        <br />
        If you didn't understand the above - read it a again until you do loser.
        <br />
        Or don't, whatever...
      </div>
    ),
  },
  {
    title: "Are the winning numbers random?",
    description: <div>yes, as random as possible.</div>,
  },
  {
    title: "How do I collect the prize when I win?",
    description: (
      <div>
        After you hit the correct number, the Pool Prize will automatically be
        sent to the wallet that submitted the entry.
        <br />
        <br />
        No action required.
      </div>
    ),
  },
  {
    title: "Does the platform make more money if I lose?",
    description: (
      <div>
        No.
        <br />
        <br />
        We are not the house, we are just a p2p platform.
        <br />
        It makes no difference to us if you win life-changing prizes or lose all
        your rent money.
        <br />
        <br />
        If you have any beef - take it up with the degen that created the pool,
        bozo.
      </div>
    ),
  },
  {
    title: "Can I create a pool?",
    description: (
      <div>
        Yes, any degen can create a pool.
        <br />
        It's FREE to create a Pool btw
      </div>
    ),
  },
  {
    title: "Can I close my pools?",
    description: (
      <div>
        NO.
        <br />
        <br />
        Pools CANNOT be closed after they are created. BEWARE.
        <br />* Even if you created it
      </div>
    ),
  },
  {
    title: "Can I change the settings of a my pools?",
    description: (
      <div>
        NO.
        <br />
        <br />
        Pools CANNOT be updated/modified/imporved/closed/changed after they are
        created. BEWARE.
        <br />* Even if you created it
      </div>
    ),
  },
  {
    title:
      "What can I do if I fucked up the settings of my pool I created and no one is entering",
    description: (
      <p>
        Play against your pool to increase the pool size, until it reachs the
        point that it becomes attractive to degens or you win your own pool.{" "}
        <br /> <br />
        Or just don't fuck up the pool when you set it up, bozo.
      </p>
    ),
  },
  {
    title: "How are the fees divided?",
    description: (
      <div>
        Every pool entry fee is divided as the following:
        <Table
          bordered={true}
          size="small"
          {...{ columns, dataSource }}
        ></Table>
      </div>
    ),
  },
  {
    title: "Who is behind this platform?",
    description: "A bunch of fucking Degens",
  },
  { title: "Do you love me?", description: "No, fuck off bozo" },
  { title: "Will you launch an NFT project?", description: "maybe" },
  {
    title: "Can I get WL?",
    description: "again, maybe, depends on the vibes tbh",
  },
];
