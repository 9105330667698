import { App, Button, Col, Row, Tag } from "antd";
import firebase from "firebase/app";

import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { EntryForm } from "../../components/EntryForm/EntryForm";
import { useFirebase } from "../../Services/FirebaseService/FirebaseService";
import "./PoolPage.scss";
import { useWallet } from "@solana/wallet-adapter-react";
import { getMsgFromExeption } from "../../utils";
import {
  GameInfo,
  GameStartResponse,
  SendRequestReponse,
  SendTransactionResponse,
  SendTransactionResponseData,
} from "../../types";
import Loader from "../../components/Loader/Loader";
import PoolHistory from "../../components/PoolHistory/PoolHistory";
import { PoolStats } from "../../components/PoolStats/PoolStats";
import { Transaction } from "@solana/web3.js";
import { GameHashPreviews } from "../../components/HashPreview/HashPreview";
import { SubmitEntryFormButton } from "../../components/EntryForm/SubmitEntryFormButton/SubmitEntryFormButton";

export const PoolPage = () => {
  const params = useParams();
  const navigate = useNavigate();
  const { publicKey, signTransaction } = useWallet();
  const { firestore, functions } = useFirebase();
  const { notification } = App.useApp();

  const [gameInfo, setGameInfo] = useState<null | GameInfo>();
  const [poolId, setPoolId] = useState(params.id);
  const [poolDoc, setPoolDoc] =
    useState<
      firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>
    >();
  const [selections, setSelections] = useState([]);
  const [busy, setBusy] = useState<null | undefined | string>();
  const [gameResult, setGameResult] = useState<
    SendTransactionResponseData | undefined
  >();
  const [gameLoading, setGameLoading] = useState(false);

  useEffect(() => {
    if (poolId) {
      return firestore.doc(`/APool/${poolId}`).onSnapshot((doc) => {
        if (doc.exists) {
          setPoolDoc(doc);
          if (doc.data()!.status === "live") {
            onGameStart();
          }
        } else {
          navigate("/");
        }
      });
    }
  }, [poolId]);

  const onGameStart = () => {
    if (poolDoc?.data()!.status === "live") {
      setGameLoading(true);
      functions
        .httpsCallable("/entry/init", { timeout: 540 })({ poolId })
        .then(({ data }: GameStartResponse) => {
          if (data.success) {
            const { hash, requestId, thread } = data;
            setGameInfo({ hash, requestId, thread });
          } else {
            throw new Error(data.error?.title, {
              cause: data.error?.description,
            });
          }
        })
        .catch((e: any) => {
          const msg = getMsgFromExeption(e);
          if (msg) {
            notification.error(msg);
          }
        })
        .finally(() => {
          setGameLoading(false);
        });
    }
  };

  const cancelEntryRequest = () => {
    functions.httpsCallable("/entry/cancel")({
      poolId: poolDoc?.id,
      requestId: gameInfo?.requestId,
      thread: gameInfo?.thread,
    });
  };

  const sendRequest = () => {
    if (!publicKey || !selections.length) return;
    setBusy("Busy creating a transaction..");
    functions
      .httpsCallable("/entry/create")({
        poolId: poolDoc?.id,
        requestId: gameInfo?.requestId,
        thread: gameInfo?.thread,
        walletAddress: publicKey?.toString(),
        selections,
      })
      .then(({ data }: SendRequestReponse) => {
        if (data.success) {
          sendTransaction(data.rawTransaction);
        } else {
          throw new Error(data.error?.title, {
            cause: data.error?.description,
          });
        }
      })
      .catch((e: any) => {
        const msg = getMsgFromExeption(e);
        if (msg) {
          notification.error(msg);
        }
        setSelections([]);
        resetGame();
        setBusy(undefined);
      });
  };

  const sendTransaction = async (rawTransaction: any) => {
    if (!signTransaction) return;
    setBusy("Waiting for your transaction confirmation..");
    try {
      const signedTransaction = await signTransaction(
        Transaction.from(rawTransaction.data)
      );
      setBusy("Waiting for result..");
      const { data } = (await functions.httpsCallable("/entry/confirm")({
        poolId: poolDoc?.id,
        requestId: gameInfo?.requestId,
        thread: gameInfo?.thread,
        rawTransaction: Array.from(signedTransaction.serialize()),
      })) as SendTransactionResponse;
      if (data.success) {
        if (data.hit) {
          notification.success({
            message: data.message,
          });
        }
        setGameResult(data);
      } else {
        throw new Error(data.error?.title, {
          cause: data.error?.description,
        });
      }
    } catch (e: any) {
      const msg = getMsgFromExeption(e);
      if (msg) {
        notification.error(msg);
      }
      cancelEntryRequest();
      resetGame();
    } finally {
      setBusy(null);
    }
  };

  const resetGame = () => {
    setGameInfo(null);
    setGameResult(undefined);
    setSelections([]);
    onGameStart();
  };

  if (!poolDoc)
    return (
      <div className="pool-page-main-container">
        {!poolDoc && (
          <div>
            Loading Pool
            <br />
            <Loader.Coin />
          </div>
        )}
      </div>
    );

  const { poolSize, status, winningWalletAddress } = poolDoc.data()!;

  return (
    <div className="pool-page-main-container">
      <PoolStats poolDoc={poolDoc} />
      {status === "live" ? (
        gameInfo ? (
          <>
            <EntryForm
              {...{
                selections,
                setSelections,
                gameResult,
                busy,
                poolDoc,
                gameInfo,
                resetGame,
              }}
            />
            <SubmitEntryFormButton
              {...{
                gameResult,
                sendRequest,
                selections,
                busy,
                poolDoc,
              }}
            />
          </>
        ) : (
          <Button
            onClick={onGameStart}
            size="large"
            style={{ fontSize: 20 }}
            disabled={gameLoading}
          >
            <Row align="middle" gutter={15}>
              <Col>
                <Loader.Coin size={32} />
              </Col>
              <Col>{gameLoading ? "IN PROCESS.." : "START GAME"}</Col>
            </Row>
          </Button>
        )
      ) : (
        <h1 style={{ fontSize: "5rem" }}>
          {winningWalletAddress === publicKey?.toString() ? (
            <>
              <Tag style={{ fontSize: "2rem" }} color="green">
                HIT
              </Tag>
              <div className="prize">
                Your prize is {(+poolSize).toLocaleString()} $SOL
              </div>
              <Button
                onClick={() => {
                  navigate("/");
                }}
              >
                Go to the list
              </Button>
            </>
          ) : (
            <div style={{ fontSize: "2rem" }}>
              This pool was taken by some motherfucker
            </div>
          )}
        </h1>
      )}
      <GameHashPreviews {...{ gameInfo, gameResult }} />

      <PoolHistory poolId={poolId} />
    </div>
  );
};
