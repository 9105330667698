import { Divider, Spin, Tabs } from "antd";
import { usePoolFilter } from "../../Services/PoolFilterService/PoolFilterService";
import { PoolPreview } from "../../components/PoolPreview/PoolPreview";
import "./PreviewPools.scss";
import { Hero } from "../../components/Hero/Hero";
import Filter from "../../components/Filter/Filter";
import { Order } from "../../components/Order/Order";
import { PoolListView } from "../../components/PoolListView/PoolListView";
import { useEffect, useState } from "react";
import { useWallet } from "@solana/wallet-adapter-react";
import WinnersList from "../../components/WinnersList/WinnersList";

export const PreviewPools = () => {
  const {
    loadingPools,
    layout,
    setCreatorWalletAddress,
    creatorWalletAddress,
    getPoolsIds,
  } = usePoolFilter();
  const { publicKey } = useWallet();
  const [poolType, setPoolType] = useState(creatorWalletAddress ? "my" : "all");

  useEffect(() => {
    if (!setCreatorWalletAddress) return;
    if (!publicKey) {
      setPoolType("all");
      setCreatorWalletAddress("");
    }
  }, [publicKey, setCreatorWalletAddress]);

  const onChangePollListType = (key: string) => {
    if (!getPoolsIds || !setCreatorWalletAddress) return;
    setPoolType(key);
    setCreatorWalletAddress(
      key === "my" && publicKey ? publicKey.toString() : ""
    );
  };

  const getTabs = () => {
    const tabs = [
      {
        label: "All Pools",
        key: "all",
        children: <PoolListView isMyList={false} />,
      },
      {
        label: "Winners",
        key: "winners",
        children: <WinnersList />,
      },
    ];

    if (publicKey) {
      tabs.splice(1, 0, {
        label: "My Pools",
        key: "my",
        children: <PoolListView isMyList />,
      });
    }

    return tabs;
  };

  return (
    <div className="preview-pools-main-container">
      <Hero />
      <Divider />
      <Filter {...{setPoolType}} />
      <Order />
      <Tabs
        activeKey={poolType}
        items={getTabs()}
        onChange={onChangePollListType}
        className="pool-type-tabs"
      />
      <div className="pools-container">
        {loadingPools && layout === "Kanban" && (
          <div style={{ marginTop: "100px" }}>
            <Spin />
          </div>
        )}
      </div>
    </div>
  );
};
