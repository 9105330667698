import "./Footer.scss";
import { Layout } from "antd";
import { TwitterCircleFilled } from "@ant-design/icons";

const Footer = () => {
  return (
    <Layout.Footer className="degen-footer">
      STAY DEGEN © {new Date().getFullYear() + " "}
      <a
        href="https://twitter.com/StayDegen"
        target="_blank"
        rel="noreferrer"
        className="twitter-link"
      >
        <TwitterCircleFilled />
      </a>
    </Layout.Footer>
  );
};

export default Footer;
