import "./MainPage.scss";
import { Button, Layout } from "antd";
import { Route, Routes, Navigate, useNavigate, Link } from "react-router-dom";
import { WalletMultiButton } from "@solana/wallet-adapter-react-ui";
import { PreviewPools } from "./PreviewPools/PreviewPools";
import { CreatePool } from "./CreatePool/CreatePool";
import { PoolPage } from "./PoolPage/PoolPage";
import { FAQ } from "./FAQ/FAQ";
import Footer from "../components/Footer/Footer";

export const MainPage = () => {
  const navigate = useNavigate();
  return (
    <Layout className="site-layout">
      <Layout.Header>
        <div className="main-page-header-container">
          <div>
            <div
              className="stay-degen-header-logo"
              onClick={() => navigate("/")}
            >
              STAY DEGEN
            </div>
          </div>
          <div className="actions-container">
            <Link to="/create-pool" key="create-pool">
              <Button key="create-pool">CREATE YOUR POOL</Button>
            </Link>
            <WalletMultiButton key="wallet" />
          </div>
        </div>
      </Layout.Header>
      <Routes>
        <Route path="/" element={<PreviewPools />} />
        <Route path="/create-pool" element={<CreatePool />} />
        <Route path="/degen-faq" element={<FAQ />} />
        <Route path="/pool/:id" element={<PoolPage />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
      <Footer />
    </Layout>
  );
};
