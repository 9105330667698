import { createContext, useContext, useEffect, useState } from "react";
import { useFirebase } from "../FirebaseService/FirebaseService";
import { SORT_OPTIONS } from "../../utils";
import { SortOption } from "../../types";

type PoolFilterData = {
  getPoolsIds?: () => void;
  showAdvancedFilters?: boolean;
  setShowAdvancedFilters?: React.Dispatch<React.SetStateAction<boolean>>;
  maxRange?: number;
  setMaxRange?: React.Dispatch<React.SetStateAction<number>>;
  minRange?: number;
  setMinRange?: React.Dispatch<React.SetStateAction<number>>;
  maxOpportunity?: number;
  setMaxOpportunity?: React.Dispatch<React.SetStateAction<number>>;
  minOpportunity?: number;
  setMinOpportunity?: React.Dispatch<React.SetStateAction<number>>;
  minEntry?: number;
  setMinEntry?: React.Dispatch<React.SetStateAction<number>>;
  maxEntry?: number;
  setMaxEntry?: React.Dispatch<React.SetStateAction<number>>;
  loadingPools?: boolean;
  setLayout?: React.Dispatch<React.SetStateAction<string>>;
  layout?: string;
  setSortBy?: React.Dispatch<React.SetStateAction<SortOption>>;
  sortBy?: SortOption;
  setCreatorWalletAddress?: React.Dispatch<React.SetStateAction<string>>;
  creatorWalletAddress?: string;
  poolDocsIds?: string[];
  page?: number;
  setPage?: React.Dispatch<React.SetStateAction<number>>;
};

const PoolFilterContext = createContext<PoolFilterData>({});

export function usePoolFilter() {
  return useContext(PoolFilterContext);
}

export const PoolFilterService = ({ children }: any) => {
  const { functions } = useFirebase();

  const [layout, setLayout] = useState("List");
  const [showAdvancedFilters, setShowAdvancedFilters] = useState(false);
  const [sortBy, setSortBy] = useState<SortOption>(SORT_OPTIONS[0]);
  const [minRange, setMinRange] = useState(2);
  const [maxRange, setMaxRange] = useState(1000);
  const [minEntry, setMinEntry] = useState(0.005);
  const [maxEntry, setMaxEntry] = useState(100);
  const [minOpportunity, setMinOpportunity] = useState(0.1);
  const [maxOpportunity, setMaxOpportunity] = useState(100);
  const [creatorWalletAddress, setCreatorWalletAddress] = useState("");
  const [loadingPools, setLoadingPools] = useState(false);
  const [poolDocsIds, setPoolDocsIds] = useState<string[]>([]);
  const [page, setPage] = useState(1);

  useEffect(() => {
    getPoolsIds();
  }, []);

  useEffect(() => {
    getPoolsIds();
  }, [creatorWalletAddress]);

  useEffect(() => {
    getPoolsIds();
  }, [sortBy]);

  const getPoolsIds = () => {
    setLoadingPools(true);
    setPoolDocsIds([]);
    functions
      .httpsCallable("/pool/find")({
        ...(creatorWalletAddress && {
          creatorWalletAddress,
        }),
        statuses: creatorWalletAddress ? ["live", "ended"] : ["live"],
        ...(!creatorWalletAddress && { minEntry, maxEntry }),
        ...(showAdvancedFilters && {
          minRange,
          maxRange,
          minOpportunity,
          maxOpportunity,
        }),
        orderby: {
          field: sortBy.field,
          direction: sortBy.direction,
        },
      })
      .then(async ({ data }: any) => {
        setPoolDocsIds(data);
        setPage(1);
        setLoadingPools(false);
      });
  };

  const value: PoolFilterData = {
    getPoolsIds,
    showAdvancedFilters,
    setShowAdvancedFilters,
    minRange,
    setMinRange,
    maxRange,
    setMaxRange,
    maxOpportunity,
    setMaxOpportunity,
    minOpportunity,
    setMinOpportunity,
    minEntry,
    setMinEntry,
    maxEntry,
    setMaxEntry,
    loadingPools,
    layout,
    setLayout,
    sortBy,
    setSortBy,
    setCreatorWalletAddress,
    creatorWalletAddress,
    poolDocsIds,
    page,
    setPage,
  };
  return (
    <PoolFilterContext.Provider value={value}>
      {children}
    </PoolFilterContext.Provider>
  );
};
