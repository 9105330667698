import firebase from "firebase/app";
import { useWallet } from "@solana/wallet-adapter-react";
import { Alert, Button } from "antd";
import { SendTransactionResponseData } from "../../../types";

type SubmitEntryFormButtonType = {
  gameResult: SendTransactionResponseData | null | undefined;
  sendRequest: () => void;
  selections: number[];
  busy: string | null | undefined;
  poolDoc: firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>;
};

export const SubmitEntryFormButton = ({
  gameResult,
  sendRequest,
  selections,
  busy,
  poolDoc,
}: SubmitEntryFormButtonType) => {
  const { publicKey } = useWallet();
  return (
    <>
      {publicKey && !gameResult && (
        <Button
          onClick={sendRequest}
          type="primary"
          size="large"
          disabled={selections.length === 0}
          loading={!!busy}
        >
          {selections.length > 0
            ? `PAY ${
                poolDoc.data()!.entryPrices[selections.length - 1]
              } $SOL for
            HIT`
            : `Select number${
                poolDoc.data()!.entryPrices.length > 1 ? "s" : ""
              }`}
        </Button>
      )}
      {!publicKey && (
        <Alert type="warning" description="Please connect your wallet to HIT" />
      )}
    </>
  );
};
