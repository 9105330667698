export type FirebaseRespone<T> = {
  data: T;
};

export type FirebaseData<T> = T;

export interface Data {
  success: boolean;
  error?: ResponseError;
}

export type ResponseError = {
  title: string;
  description: string;
};

export interface GameStartResponseData extends Data {
  hash: string;
  requestId: string;
  thread: string;
}

export type GameStartResponse = FirebaseRespone<GameStartResponseData>;

export interface SendRequestReponseData extends Data {
  rawTransaction: {
    type: "Buffer";
    data: number[];
  };
  requestId: string;
}

export type SendRequestReponse = FirebaseRespone<SendRequestReponseData>;

export interface SendTransactionResponseData extends Data {
  hash: string;
  hashMessage: string;
  message: string;
  prizeSize: number;
  hit: boolean;
  num: number;
  txid: string;
}

export type SendTransactionResponse =
  FirebaseRespone<SendTransactionResponseData>;

export type GameInfo = {
  hash: string;
  requestId: string;
  thread: string;
};

export enum OrderByFields {
  MinOpportunity = "minOpportunity",
  MaxOpportunity = "maxOpportunity",
  PoolSize = "poolSize",
  MinEntry = "minEntry",
  Range = "range",
}

export type SortOption = {
  label: string;
  value: number;
  field: OrderByFields;
  direction: string;
};
