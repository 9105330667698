import "./EntryForm.scss";
import firebase from "firebase/app";
import { useState } from "react";
import { EntryFormOption } from "./EntryFormOption/EntryFormOption";
import { Button, Divider, Space, Tag } from "antd";
import { GameInfo, SendTransactionResponseData } from "../../types";
import Loader from "../Loader/Loader";

type PoolEntryType = {
  poolDoc: firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>;
  gameInfo: GameInfo;
  gameResult?: SendTransactionResponseData;
  selections: number[];
  setSelections: React.Dispatch<React.SetStateAction<never[]>>;
  busy?: any;
  resetGame: () => void;
};

export const EntryForm = ({
  poolDoc,
  resetGame,
  selections,
  setSelections,
  gameResult,
  busy,
}: PoolEntryType) => {
  const [options] = useState(
    (() => {
      const nums: number[] = [];
      for (let index = 0; index < poolDoc.data()!.range; index++) {
        nums.push(index + 1);
      }
      return nums;
    })()
  );

  const isMaxed = () => selections.length >= poolDoc.data()!.entryPrices.length;

  const getEmptyLuckyNumbers = () => {
    if (selections.length === poolDoc.data()!.entryPrices.length) {
      return null;
    }
    const howMany = poolDoc.data()!.entryPrices.length - selections.length;
    const arr = [];
    for (let i = 0; i < howMany; i++) {
      arr.push(
        <div key={i} className="selection-item gray">
          <div />
        </div>
      );
    }
    return arr;
  };

  return (
    <div className="entry-form-container">
      <div style={{ width: "100%", margin: "0 auto", maxWidth: 800 }}>
        {gameResult && (
          <div className="game-result">
            <Tag
              style={{ fontSize: "2rem" }}
              color={gameResult.hit ? "green" : "red"}
            >
              {gameResult.hit ? "HIT" : "MISS"}
            </Tag>
            <div className="title">{gameResult.message}</div>
            <div className="number-title">Winning number is</div>
            <div className="selection-container">
              <div className="selection-item">
                <div>{gameResult.num}</div>
              </div>
            </div>
            {!gameResult.hit && (
              <Button
                onClick={resetGame}
                size="large"
                style={{ fontSize: 20, marginTop: "50px", width: "300px" }}
              >
                <Loader.Coin size={32} />
                <span style={{ marginInline: "20px" }}>PLAY AGAIN</span>
                <Loader.Coin size={32} />
              </Button>
            )}
          </div>
        )}
      </div>
      {!gameResult &&
        (!!busy ? (
          <>
            <Loader.Circle />
            <h2>{busy}</h2>
          </>
        ) : (
          <div className="option-container">
            {options &&
              options.map((option) => {
                return (
                  <div key={option}>
                    <EntryFormOption
                      isMaxed={isMaxed}
                      selections={selections}
                      setSelections={setSelections}
                      index={option}
                    />
                  </div>
                );
              })}
          </div>
        ))}
      <Divider />
      <>
        <div className="selection-title">Your lucky numbers:</div>
        <div className="selection-container">
          {selections.map((option) => {
            return (
              <div key={option} className="selection-item">
                <div>{option}</div>
              </div>
            );
          })}
          {getEmptyLuckyNumbers()}
        </div>
      </>
      <div className="submission-container">
        <Space />
      </div>
    </div>
  );
};

export const HowToPlayPreview = () => {
  const [selections, setSelections] = useState([]);
  const isMaxed = () => selections.length >= 1;

  return (
    <div className="faq-option-container">
      {[1, 2, 3].map((option) => {
        return (
          <div key={option}>
            <EntryFormOption
              isMaxed={isMaxed}
              selections={selections}
              setSelections={setSelections}
              index={option}
            />
          </div>
        );
      })}
    </div>
  );
};
