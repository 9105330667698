import "./scss/index.scss";

const Circle = () => {
  return (
    <div className="loader circle">
      <div />
      <div />
    </div>
  );
};

type CoinProps = { size?: number };
const Coin = ({ size = 40 }: CoinProps) => {
  return (
    <div className="loader coin" style={{ width: size, height: size }}>
      <div />
      <div />
    </div>
  );
};

const Loader = { Circle, Coin };

export default Loader;
