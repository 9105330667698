import type { ColumnsType } from "antd/lib/table";
import { DataType } from "./types";
import { Tooltip } from "antd";
import solscanLogo from "../../assets/images/solscan-logo.png";
import { convertFloatToView } from "../../utils";

export const columns: ColumnsType<DataType> = [
  {
    title: "Address",
    dataIndex: "walletAddress",
    render: (value) => `${value.slice(0, 4)}...${value.slice(-4)}`,
  },
  {
    title: "Reward amount",
    dataIndex: "realRewardAmount",
    render: (value, row) => (
      <div style={{ display: "flex", alignItems: "center" }}>
        {(+value).toLocaleString()}
        <Tooltip title="View in Solscan">
          <a
            href={`https://solscan.io/tx/${row?.prizeSentTxid}`}
            target="_blank"
            rel="noreferrer"
            style={{ display: "inline-block", marginLeft: 5 }}
          >
            <img
              src={solscanLogo}
              alt="Solscan"
              width={20}
              style={{ display: "block" }}
            />
          </a>
        </Tooltip>
      </div>
    ),
  },
  {
    title: "X",
    dataIndex: "userPaid",
    render: (value, row) => {
      return row?.realRewardAmount && value
        ? `${convertFloatToView(row?.realRewardAmount / value)}x`
        : "-";
    },
  },
  {
    title: "Date",
    dataIndex: "created",
    render: (value) => {
      const date = value.toDate();
      return `${date.toDateString()} ${date.toLocaleTimeString("en-US")}`;
    },
  },
];
