import { useEffect, useState } from "react";
import { useFirebase } from "../../Services/FirebaseService/FirebaseService";
import { Table } from "antd";
import { DataType } from "./types";
import { columns } from "./columns";

const PoolHistory = ({ poolId }: any) => {
  const { firestore } = useFirebase();
  const [history, setHistory] = useState<DataType[] | null>(null);

  useEffect(() => {
    return firestore
      .collection(`/APool/${poolId}/APoolEntryLog/`)
      .limit(10)
      .orderBy("created", "desc")
      .onSnapshot(({ docs }) => {
        const data = docs.map((doc) => {
          const {
            walletAddress,
            selections,
            solAmount,
            created,
            randomNum,
            status,
            txid,
          } = doc.data() as DataType;
          return {
            key: doc.id,
            walletAddress,
            selections,
            solAmount,
            created,
            randomNum,
            status,
            txid,
          };
        });
        setHistory(data);
      });
  }, []);

  return (
    <Table
      loading={!history}
      locale={{ emptyText: "No history found in this pool" }}
      size="large"
      columns={columns}
      dataSource={history as DataType[]}
      pagination={false}
      scroll={{ x: 700 }}
      style={{ margin: "50px 0" }}
    />
  );
};

export default PoolHistory;
