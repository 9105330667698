import { App, Button, Form, Input, Tooltip } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { CopyOutlined } from "@ant-design/icons";
import { GameInfo, SendTransactionResponseData } from "../../types";

type HashPreviewType = {
  rows?: number;
  children: any;
  toolip: string;
  title: string;
};

type GameHashPreviewsType = {
  gameInfo: GameInfo | null | undefined;
  gameResult: SendTransactionResponseData | null | undefined;
};

export const GameHashPreviews = ({
  gameInfo,
  gameResult,
}: GameHashPreviewsType) => {
  return (
    <Form layout="vertical">
      {gameResult && (
        <HashPreview
          title={"Original Hash Message"}
          toolip={
            "What is hash message and why is it needed you can read in the FAQ"
          }
        >
          {gameResult?.hashMessage}
        </HashPreview>
      )}
      {gameInfo && (
        <HashPreview
          title={"Game Hash"}
          toolip={"What is hash and why is it needed you can read in the FAQ"}
        >
          {gameInfo.hash}
        </HashPreview>
      )}
      {gameResult && (
        <Button
          onClick={() => {
            window.open(
              "https://10015.io/tools/sha512-encrypt-decrypt",
              "_blank"
            );
          }}
        >
          Verify
        </Button>
      )}
    </Form>
  );
};

export const HashPreview = ({
  children,
  rows,
  toolip,
  title,
}: HashPreviewType) => {
  const { notification } = App.useApp();

  return (
    <Form.Item style={{ marginTop: "30px" }} label={title} tooltip={toolip}>
      <Input.Group compact>
        <TextArea
          rows={rows || 1}
          style={{ width: "calc(100% - 31px)" }}
          value={children}
          size="small"
          disabled
        />
        <Tooltip title="Copy hash message">
          <Button
            icon={<CopyOutlined />}
            onClick={() => {
              navigator.clipboard
                .writeText(children)
                .then(() => {
                  notification.success({
                    message: "Message copied to clipboard",
                  });
                })
                .catch((err) => {
                  notification.error({
                    message: "Error",
                    description: err.message,
                  });
                });
            }}
          />
        </Tooltip>
      </Input.Group>
    </Form.Item>
  );
};
