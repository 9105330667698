import { Table } from "antd";
import { useEffect, useState } from "react";
import { useFirebase } from "../../Services/FirebaseService/FirebaseService";
import { PAGINATION_LIMIT } from "../../utils";
import { DataType } from "./types";
import { columns } from "./columns";

const WinnersList = () => {
  const { firestore } = useFirebase();

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<DataType[]>([]);

  useEffect(() => {
    return firestore
      .collectionGroup("APoolPrizeTransaction")
      .orderBy("created", "desc")
      .where("status", "==", "done")
      .limit(50)
      .onSnapshot(({ docs }) => {
        setData(
          docs.map((doc) => {
            const {
              walletAddress,
              realRewardAmount,
              userPaid,
              created,
              prizeSentTxid,
            } = doc.data();
            return {
              key: doc.id,
              walletAddress,
              realRewardAmount,
              userPaid,
              created,
              prizeSentTxid,
            };
          })
        );
        setLoading(false);
      });
  }, []);

  return (
    <Table
      loading={loading}
      locale={{ emptyText: "No winners" }}
      size="large"
      columns={columns}
      dataSource={data}
      style={{ minWidth: "50vw", maxWidth: "100vw" }}
      scroll={{ x: 500 }}
      pagination={{
        defaultCurrent: 1,
        pageSize: PAGINATION_LIMIT,
      }}
    />
  );
};

export default WinnersList;
