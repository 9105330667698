import { useWallet } from "@solana/wallet-adapter-react";
import { Transaction } from "@solana/web3.js";
import {
  Alert,
  App,
  Button,
  Card,
  Divider,
  Form,
  Input,
  InputNumber,
} from "antd";
import { useState } from "react";
import { useFirebase } from "../../Services/FirebaseService/FirebaseService";
import "./CreatePool.scss";
import { convertFloatToView, getMsgFromExeption } from "../../utils";
import { useNavigate } from "react-router-dom";
import {
  MAX_ENTRY_PRICE,
  MAX_INITIAL_POOL_SIZE,
  MAX_RANGE,
  MIN_ENTRY_PRICE,
  MIN_INITIAL_POOL_SIZE,
  MIN_RANGE,
} from "./config";

export const CreatePool = () => {
  const [form] = Form.useForm();
  const { publicKey, signTransaction } = useWallet();
  const { firestore, functions } = useFirebase();
  const navigate = useNavigate();
  const { message, notification } = App.useApp();

  const formTitle = Form.useWatch("title", form);
  const formPoolSize = Form.useWatch("initial_sol", form);
  const formPriceArr = Form.useWatch("price", form);

  const [loading, setLoading] = useState(false);
  const [range, setRange] = useState<any>();
  const [priceEntries, setPiceEntries] = useState<any>();

  const onFinish = (values: any) => {
    const { initial_sol, price, range, title } = values;
    sendRequest(initial_sol, range, price, title);
  };

  const sendRequest = async (
    initPoolSize: number,
    range: number,
    entryPrices: number[],
    title: string
  ) => {
    if (!publicKey || !signTransaction) return;
    setLoading(true);
    try {
      const hide = message.loading(
        "Processing a request to create a pool... ",
        0
      );
      const { data } = await functions.httpsCallable("/pool/create")({
        walletAddress: publicKey.toString(),
        initPoolSize,
        entryPrices,
        title,
        range,
      });
      hide();
      if (!data.success) {
        throw new Error(data.error?.title, {
          cause: data.error?.description,
        });
      }
      const signedTransaction = await signTransaction(
        Transaction.from(data.rawTransaction.data)
      );
      await sendSignedTransaction(signedTransaction, data.requestId);
    } catch (e) {
      const msg = getMsgFromExeption(e);
      if (msg) {
        notification.error(msg);
      }
    } finally {
      setLoading(false);
    }
  };

  const sendSignedTransaction = async (
    transaction: Transaction,
    requestId: string
  ) => {
    const { data } = await functions.httpsCallable("/pool/submit")({
      poolId: requestId,
      rawTransaction: Array.from(transaction.serialize()),
    });
    if (data.success) {
      const hide = message.loading("Busy confirming your transaction... ", 0);
      const didGoLive = await getCreationResponse(requestId);
      hide();
      if (didGoLive) {
        navigate(`/pool/${requestId}`);
      } else {
        throw new Error(data.error?.title, {
          cause: data.error?.description,
        });
      }
    } else {
      throw new Error(data.error?.title, {
        cause: data.error?.description,
      });
    }
  };

  const getCreationResponse = (poolId: string) =>
    new Promise((resolve) => {
      const unsubscribe = firestore
        .doc(`/APool/${poolId}`)
        .onSnapshot((doc) => {
          const { status } = doc.data()!;
          if (status === "pending") return;

          if (status === "failed") {
            unsubscribe();
            return resolve(false);
          }
          if (status === "live") {
            unsubscribe();
            return resolve(true);
          }
        });
    });

  const breakEven = () => {
    if (!formPoolSize || !formPriceArr.every((v: any) => typeof v === "number"))
      return null;
    const min = Math.min(...formPriceArr);
    const max = Math.max(...formPriceArr);
    const trysByMax = convertFloatToView(formPoolSize / (max / 2));
    const trysByMin = convertFloatToView(formPoolSize / (min / 2));
    return (
      <p>
        You will Break-Even after{" "}
        <span className="accent">
          {formPriceArr.length > 1 && trysByMax !== trysByMin
            ? `${trysByMax} - `
            : ""}
          {trysByMin} Trys
        </span>
      </p>
    );
  };

  const winningOdds = () => {
    if (!range || !priceEntries || range <= priceEntries) return null;
    const piecePercent = 100 / range;
    const to = priceEntries > 1 ? piecePercent * priceEntries : null;
    return (
      <>
        <p>
          Winning Odds is{" "}
          <span className="accent">
            {convertFloatToView(piecePercent)}%
            {to ? ` - ${convertFloatToView(to)}%` : ""}
          </span>
        </p>
        <p>
          Statistically, a player will hit after{" "}
          <span className="accent">
            {to ? `${convertFloatToView(100 / to)} - ` : ""}
            {convertFloatToView(100 / piecePercent)} Trys
          </span>
        </p>
      </>
    );
  };

  return (
    <div className="create-pool-main-container">
      <div className="title">Create A Pool</div>
      <div className="create-pool-form-container">
        <Card title="Create Pool Form" className="create-pool-card">
          <Form
            initialValues={{}}
            onFinish={onFinish}
            layout="vertical"
            form={form}
          >
            <Form.Item
              help="The title of your pool will be visible across the entire platform."
              label="Pool Title"
              id="title"
              name="title"
            >
              <Input
                width="200px"
                size="large"
                maxLength={100}
                minLength={2}
                disabled={loading}
              />
            </Form.Item>
            <Divider />
            <Form.Item
              help="How much initial liquidity you will provide into the pool."
              label="Initial SOL in pool"
              id="initialSol"
              required
              name="initial_sol"
              rules={[
                {
                  required: true,
                  min: MIN_INITIAL_POOL_SIZE,
                  type: "number",
                  max: MAX_INITIAL_POOL_SIZE,
                  message: `Please enter a valid initial pool from ${MIN_INITIAL_POOL_SIZE} to ${MAX_INITIAL_POOL_SIZE}`,
                },
              ]}
            >
              <InputNumber
                size="large"
                precision={3}
                min={MIN_INITIAL_POOL_SIZE}
                step={MIN_INITIAL_POOL_SIZE}
                max={MAX_INITIAL_POOL_SIZE}
                addonAfter="$SOL"
                disabled={loading}
              />
            </Form.Item>
            <Divider />
            <Form.Item
              label="Range"
              id="range"
              help="Total amount of numbers a player must choose from (2 - 1,000)"
              name="range"
              required
              rules={[
                {
                  required: true,
                  min: MIN_RANGE,
                  type: "number",
                  max: MAX_RANGE,
                  message: `Please enter a valid option range between ${MIN_RANGE} - ${MAX_RANGE}`,
                },
              ]}
            >
              <InputNumber
                precision={0}
                min={MIN_RANGE}
                size="large"
                max={MAX_RANGE}
                onChange={(e) => setRange(e || MIN_RANGE)}
                addonAfter="Numbers to pick from"
                disabled={loading}
              />
            </Form.Item>
            <Divider />
            <Form.Item
              label="Number of price entries"
              id="entry_prices"
              help={
                <p>
                  How many number the players can pick in a single entry.
                  <br />
                  You NEED to assign a different entry price for each option.
                  <br />
                  SEE BELOW:
                </p>
              }
              name="entry_prices"
              required
              rules={[
                {
                  required: true,
                  min: 1,
                  type: "number",
                  max: range - 1 || 0,
                  message: `Please enter a valid price entries between 1 - ${
                    range - 1
                  }`,
                },
              ]}
            >
              <InputNumber
                precision={0}
                min={1}
                onChange={(e) => setPiceEntries(e || 1)}
                max={range - 1}
                size="large"
                addonAfter="Entry Prices"
                disabled={loading}
              />
            </Form.Item>
            <div className="ml-3">
              {new Array(priceEntries).fill(1).map((_item, index) => {
                return (
                  <Form.Item
                    label={`Price for ${index + 1} picks`}
                    key={index}
                    help={`The entry fee for picking ${index + 1} number${
                      index >= 1 ? "s" : ""
                    }`}
                    name={["price", index]}
                    required
                    rules={[
                      {
                        required: true,
                        min: MIN_ENTRY_PRICE,
                        max: MAX_ENTRY_PRICE,
                        type: "number",
                        message: `Please enter a valid price that is between ${MIN_ENTRY_PRICE} - ${MAX_ENTRY_PRICE} $SOL`,
                      },
                    ]}
                  >
                    <InputNumber
                      precision={3}
                      min={MIN_ENTRY_PRICE}
                      max={MAX_ENTRY_PRICE}
                      size="small"
                      step={0.001}
                      addonAfter="$SOL"
                      disabled={loading}
                    />
                  </Form.Item>
                );
              })}
            </div>
            <Divider />
            {!publicKey && (
              <Alert
                type="warning"
                description="Please connect your wallet to create a pool"
              />
            )}
            {publicKey && (
              <Button type="primary" htmlType="submit" loading={loading}>
                CREATE POOL
              </Button>
            )}
          </Form>
        </Card>
        <Card title="Pool preview" className="info-card">
          <h1>{formTitle ? formTitle : "Change your pool settings!"}</h1>
          {formPoolSize && (
            <div className="solana-number" style={{ fontSize: "1.75rem" }}>
              <img
                src="https://cryptologos.cc/logos/solana-sol-logo.svg?v=022"
                alt="Solana"
              />
              {formPoolSize} $SOL
            </div>
          )}
          <Divider />
          {range && (
            <p>
              Users can pick {priceEntries > 1 ? "" : "a "} number
              {priceEntries > 1 ? "s" : ""} between{" "}
              <span className="accent">1 - {range}</span>
            </p>
          )}
          {priceEntries && (
            <p>
              User can pick up-to{" "}
              <span className="accent">{priceEntries} numbers</span> in one
              round
            </p>
          )}
          {breakEven()}
          {winningOdds()}
        </Card>
      </div>
    </div>
  );
};
