import { Divider, Tooltip } from "antd";
import firebase from "firebase/app";
import solscanLogo from "../../assets/images/solscan-logo.png";
import "./PoolStats.scss";

type PoolStatsType = {
  poolDoc: firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>;
};

export const PoolStats = ({ poolDoc }: PoolStatsType) => {
  const {
    poolSize,
    title,
    range,
    entryPrices,
    walletAddress,
    creatorWalletAddress,
    created,
    status,
    trys,
  } = poolDoc.data()!;

  const getChanceText = () => {
    let text = `${((1 / range) * 100).toLocaleString()}%`;

    if (entryPrices.length > 1) {
      text += ` - ${((entryPrices.length / range) * 100).toLocaleString()}%`;
    }
    return text;
  };

  const getX = () => (+poolSize / entryPrices[0]).toLocaleString();
  const getTrys = () => (+trys || 0).toLocaleString();

  return (
    <div className="pool-stats-container">
      <div className="pool-size">
        <div className="pool">
          <img
            className="sol-icon"
            src="https://cryptologos.cc/logos/solana-sol-logo.svg?v=022"
            alt="avatar"
          />
          {(+poolSize).toLocaleString()}
          <Tooltip title="View on Solscan">
            <a
              href={`https://solscan.io/account/${walletAddress}`}
              target="_blank"
              rel="noreferrer"
              style={{ display: "inline-block", marginLeft: 5 }}
            >
              <img
                src={solscanLogo}
                alt="Solscan"
                width={30}
                style={{ display: "block" }}
              />
            </a>
          </Tooltip>
        </div>
      </div>
      <div className="by">
        {title || "Random Degen"}{" "}
        <Tooltip title="View on Solscan">
          <a
            href={`https://solscan.io/account/${creatorWalletAddress}`}
            target="_blank"
            rel="noreferrer"
            style={{ display: "inline-block", marginLeft: 5 }}
          >
            <img
              src={solscanLogo}
              alt="Solscan"
              width={20}
              style={{ display: "block" }}
            />
          </a>
        </Tooltip>
      </div>

      <div className="stats-container">
        <div className="stat">
          <div className="title">Opportunity</div>
          <div className="value"> {getX()}X</div>
        </div>
        <Divider style={{ height: "30px" }} type="vertical" dashed />
        <div className="stat">
          <div className="title">Hit Odds</div>
          <div className="value"> {getChanceText()}</div>
        </div>
        <Divider style={{ height: "30px" }} type="vertical" dashed />
        <div className="stat">
          <div className="title">Tries</div>
          <div className="value"> {getTrys()}</div>
        </div>
      </div>

      <Divider dashed />
    </div>
  );
};
