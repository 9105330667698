import firebase from "firebase/app";
import { Pagination, Table, Tag } from "antd";
import { useEffect, useState } from "react";
import { usePoolFilter } from "../../Services/PoolFilterService/PoolFilterService";
import type { ColumnsType } from "antd/es/table";
import "./PoolListView.scss";
import { useNavigate } from "react-router-dom";
import { CheckCircleOutlined, SyncOutlined } from "@ant-design/icons";
import { PAGINATION_LIMIT } from "../../utils";
import { useFirebase } from "../../Services/FirebaseService/FirebaseService";

interface DataType {
  key: string | number;
  pool: any;
  entry: any;
  chance: any;
  trys: any;
  range: any;
  x: any;
}

const columns: ColumnsType<DataType> = [
  {
    title: "Size $SOL",
    dataIndex: "pool",
    fixed: true,
    width: 150,
  },
  {
    title: "Opportunity",
    dataIndex: "x",
  },
  {
    title: "Entry Price",
    dataIndex: "entry",
  },
  {
    title: "Tries",
    dataIndex: "trys",
  },
  {
    title: "Hit Chance",
    dataIndex: "chance",
  },
  {
    title: "Range",
    dataIndex: "range",
  },
];

export const PoolListView = ({ isMyList }: { isMyList: boolean }) => {
  const navigate = useNavigate();
  const { firestore } = useFirebase();
  const [data, setData] = useState<any>([]);
  const { loadingPools, poolDocsIds, page, setPage } = usePoolFilter();
  const [pages, setPages] = useState<string[][]>([]);
  const [loadingSnapShots, setLoadingPools] = useState(false);

  useEffect(() => {
    if (poolDocsIds) {
      const groups: string[][] = [];
      const poolDocsIdsCopy = [...poolDocsIds];
      while (poolDocsIdsCopy.length > 0)
        groups.push(poolDocsIdsCopy.splice(0, PAGINATION_LIMIT));
      setPages(groups);
    }
  }, [poolDocsIds, page]);

  useEffect(() => {
    const group = pages[(page || 1) - 1];
    setData([]);
    setLoadingPools(true);
    if (!group || group.length === 0) {
      setLoadingPools(false);
      return;
    }
    const newData: DataType[] = [];
    for (const docId of group) {
      newData.push(getRowPreview(isMyList, docId));
    }
    setData([...newData]);
    const unsubs = group.map((docId, index) => {
      const unsub = firestore.doc(`/APool/${docId}`).onSnapshot((doc) => {
        newData[index] = getRowPreview(isMyList, docId, doc);
        setData([...newData]);
        setLoadingPools(false);
      });
      return unsub;
    });
    return () =>
      unsubs.forEach((unsub) => {
        unsub();
      });
  }, [pages]);

  return (
    <div className="pool-listview-container">
      <Table
        loading={loadingSnapShots || loadingPools}
        locale={{ emptyText: "You have no Pools degen" }}
        onRow={(record) => {
          return {
            onClick: () => {
              if (typeof record.key === "string")
                navigate(`/pool/${record.key}`);
            },
          };
        }}
        size="large"
        pagination={false}
        columns={columns}
        dataSource={data}
        style={{ minWidth: "50vw", maxWidth: "100vw" }}
        scroll={{ x: 700 }}
      />
      {!!poolDocsIds?.length && poolDocsIds.length > PAGINATION_LIMIT && (
        <div className="pools-pagination">
          <Pagination
            current={page}
            defaultCurrent={1}
            showSizeChanger={false}
            pageSize={PAGINATION_LIMIT}
            onChange={setPage}
            total={poolDocsIds?.length}
          />
        </div>
      )}
    </div>
  );
};

const getChanceText = (range: number, entries: number) => {
  let text = `${((1 / range) * 100).toLocaleString()}%`;
  if (entries > 1) {
    text += ` - ${((entries / range) * 100).toLocaleString()}%`;
  }
  return text;
};

const getRowPreview = (
  isMyList: boolean,
  docId: string,
  doc?: firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>
) => {
  const { poolSize, range, entryPrices, trys, title, status } =
    doc?.data()! || {};

  return {
    key: docId,
    pool: (
      <>
        <div className="pool-size">
          <img
            className="sol-icon"
            src="https://cryptologos.cc/logos/solana-sol-logo.svg?v=022"
            alt="avatar"
          />
          {poolSize && (+poolSize).toLocaleString()}
        </div>
        <div className="pool-title">{title}</div>
        {
          <div style={{ marginTop: 5 }}>
            {status && (
              <>
                {isMyList && status === "live" && (
                  <Tag icon={<SyncOutlined spin />} color="processing">
                    LIVE
                  </Tag>
                )}
                {status === "ended" && (
                  <Tag icon={<CheckCircleOutlined />} color="default">
                    ENDED
                  </Tag>
                )}
              </>
            )}
          </div>
        }
      </>
    ),
    entry: (
      <div className="entry-price">
        <img
          className="sol-icon"
          src="https://cryptologos.cc/logos/solana-sol-logo.svg?v=022"
          alt="avatar"
        />
        {(entryPrices && (+entryPrices?.[0])?.toLocaleString()) || ""}
      </div>
    ),
    chance: <div>{range && getChanceText(range, entryPrices.length)}</div>,
    trys: trys !== undefined && <div>{(+(trys || 0)).toLocaleString()}</div>,
    x: poolSize && (
      <div className="x">
        {(+poolSize / entryPrices[0]).toLocaleString()}
        <div className="x-text">x</div>
      </div>
    ),
    range: <div>{range && <>1 - {range}</>}</div>,
  };
};
